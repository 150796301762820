import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { StyledBody } from "./styled";
import MainNavigation from "@organisms/Navigation";
import { useDevice } from "@hooks";
import LogoMolecule from "@molecules/Logo";
import { messageService } from "@utils/messagesService";
import { useLocation } from "react-router-dom";
import { COLORS } from "@theme/Colors";
import LogoImg from "@resources/images/tc2-fl.png";
import ErrorBoundary from "@molecules/ErrorBoundary";

function Copyright() {
  const device = useDevice();
  return (
    <Box>
      {device.type === "mobile" ? (
        <Box sx={{ textAlign: "center" }}>
          <LogoMolecule src={LogoImg} href={"/"}></LogoMolecule>
        </Box>
      ) : (
        <Box className="align-center fit-center">
          <Typography
            sx={{ textAlign: "center", color: COLORS.gray.shade1 }}
            variant="body2"
          >
            &copy; Copyright 2023 -
            <Link
              color={COLORS.gray.shade1}
              target="_blank"
              href="https://www.tc2group.it/?lang=en"
            >
              TC2 Group
            </Link>
          </Typography>
          &nbsp;<LogoMolecule src={LogoImg} href={"/"}></LogoMolecule>
        </Box>
      )}
    </Box>
  );
}

const LayoutBase = ({ children, withoutContainer }: any) => {
  const [footerFixed, setFooterFixed] = useState(false);
  const location = useLocation();

  const hideFooter =
    location.pathname.includes("inspector") &&
    location.pathname.includes("details");

  useEffect(() => {
    const subS = messageService.getMessage().subscribe((message) => {
      if (message && message.text === "footer-fixed") {
        setFooterFixed(true);
      } else if (message && message.text === "footer-relative") {
        setFooterFixed(false);
      }
    });
    return () => {
      subS.unsubscribe();
    };
  }, []);

  return (
    <>
      <ErrorBoundary>
        <MainNavigation></MainNavigation>
      </ErrorBoundary>
      <StyledBody>
        {!withoutContainer ? (
          <Container
            component="main"
            sx={{ mt: { xs: 7, md: 8, sm: 7 }, mb: 2 }}
            maxWidth="lg"
          >
            {children}
          </Container>
        ) : (
          <Box>{children}</Box>
        )}

        {!hideFooter && (
          <Box
            component="footer"
            sx={{
              backgroundColor: COLORS.gray.shade2,
              py: 3,
              px: 2,
              mt: "auto",
              position: footerFixed ? "fixed" : "relative",
              bottom: "0px",
              width: "100%",
              paddingTop: { xs: "6px", md: "20px" },
              paddingBottom: { xs: "6px", md: "20px" },
            }}
          >
            <Container maxWidth="sm">
              <Copyright />
            </Container>
          </Box>
        )}
      </StyledBody>
    </>
  );
};

export default LayoutBase;
