import { useState, ChangeEvent, memo, useCallback } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import type { IDropzoneFile } from "@interfaces/IDropzoneFile";
import type { IDocument } from "@interfaces/IDocument";
import type { IDocumentsOrganismProps } from "./interfaces";
import { t, Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DropzoneOrganism from "@organisms/Dropzone";
import DocumentsService from "@services/DocumentsService";
import { Wrapper, GroupActionButton } from "./styled";
import { IconButton } from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import { useConfirm } from "material-ui-confirm";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import ReportRowItem from "@molecules/ReportRowItem";

const ReportUploadOrganism = ({
  model_id,
  model_type,
  token,
  on_upload,
  allow_multiple,
  cant_delete
}: IDocumentsOrganismProps) => {
  const { data, refetch, isLoading } = useQuery<unknown, unknown, IDocument[]>(
    `activity-documents${model_id}`,
    () =>
      DocumentsService.get("", { model_id, model_type }, false, false, undefined, token ? {
        'Authorization': 'Bearer ' + token
      } : undefined).then(
        (res: any) => res.data,
      ),
    { refetchOnWindowFocus: false, cacheTime: 0, refetchOnMount: true },
  );

  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [isFilesUploading, setIsFilesUploading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [previewFile, setPreviewOpen] = useState<IDocument | null>(null);
  const [blobUrl, setBlobUrl] = useState<any>(null);
  const handlePreviewClose = () => setPreviewOpen(null);
  const items = data || [];
  // const selectedItemsLength = selectedItems.length;
  const hasSelectedItems = !!selectedItems.length;
  const confirm = useConfirm();

  const handleDownload = useCallback((item: IDocument) => {
    DocumentsService.download(item.id, item.file, token ? {
      'Authorization': 'Bearer ' + token
    } : undefined)
      .then((res: any) => {
        toast(res.getMsgString(), {
          type: res.hasErrors() ? "error" : "success",
        });
      })
      .catch((res: any) => {
        toast(t`Generic error`, { type: "error" });
      });
  }, []);

  const handleDelete = useCallback(
    (item: IDocument) => {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        DocumentsService.delete(item.id)
          .then((res: any) => {
            toast(res.getMsgString(), {
              type: res.hasErrors() ? "error" : "success",
            });
            refetch();
          })
          .catch((res: any) => {
            toast(t`Generic error`, { type: "error" });
          });
      });
    },
    [refetch],
  );

  const handleView = useCallback((item: IDocument) => {
    DocumentsService.getFile(item.id, item.file, token ? {
      'Authorization': 'Bearer ' + token
    } : undefined)
      .then((res: any) => {
        const dataType = res.headers["content-type"];
        const binaryData: Array<any> = [];
        binaryData.push(res.blob);
        const url = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType }),
        );
        setPreviewOpen(item);
        setBlobUrl(url);
      })
      .catch((res: any) => {
        toast(t`Generic error`, { type: "error" });
      });
  }, []);

  const toggleSelectSingleItem = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: number) => {
      if (event.target.checked) {
        setSelectedItems((prevState) => [...prevState, id]);
      } else {
        setSelectedItems((prevState) =>
          prevState.filter((selectedId) => selectedId !== id),
        );
      }
    },
    [],
  );

  const handleCloseDropzone = useCallback(() => {
    setIsCreateMode(false);
  }, []);

  const handleOpenDropzone = () => {
    setIsCreateMode(true);
    setSelectedItems([]);
  };

  const handleUploadFiles = useCallback(
    (files: IDropzoneFile[]) => {
      setIsFilesUploading(true);

      const formData = new FormData();

      files.forEach(({ file }) => formData.append("files[]", file));
      formData.set("model_id", `${model_id}`);
      formData.set("model_type", model_type);

      // Upload documents
      DocumentsService.create(formData, false, token ? {
        'Authorization': 'Bearer ' + token
      } : undefined)
        .then((res: any) => {
          toast(res.getMsgString(), {
            type: res.hasErrors() ? "error" : "success",
          });
          refetch();
          if(on_upload) {
            on_upload();
          }
          setIsCreateMode(false);
        })
        .catch((res: any) => {
          toast(t`Generic error`, { type: "error" });
        })
        .finally(() => {
          setIsFilesUploading(false);
        });
    },
    [model_id, model_type, refetch],
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: { md: "100%", /*md: 752,*/ xs: "100%" },
      }}
    >
      <Box sx={{ display: "grid", gridTemplateColumns: "100%" }}>
        {isCreateMode ? (
          <Box p={1}>
            <DropzoneOrganism
              accept={{
                "text/html": [".pdf", ".png", ".jpg", ".jpeg", ".doc", ".docs", ".docx", ".zip", ".xml", ".pptx", ".xls", ".csv", ".xlsx"],
              }}
              maxFiles={20}
              isLoading={isFilesUploading}
              handleUploadFiles={handleUploadFiles}
              handleCloseDropzone={handleCloseDropzone}
            />
          </Box>
        ) : (
          <Wrapper>
            {allow_multiple || (!items || !items.length) ? (
              <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
                <GroupActionButton
                  sx={
                    !hasSelectedItems
                      ? {
                          margin: "0 auto",
                          maxWidth: "50%",
                        }
                      : {}
                  }
                  color="primary"
                  onClick={handleOpenDropzone}
                >
                  <Trans>Upload report</Trans>
                </GroupActionButton>
              </Stack>
            ) : (
              <></>
            )}
            {isLoading && (
              <Box mt={1}>
                <SpinnerAtom></SpinnerAtom>
              </Box>
            )}
            {items && items.length ? (
              <TableContainer
                sx={{
                  maxHeight: 440,
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ display: "none" }}
                        padding="none"
                      ></TableCell>
                      <TableCell>
                        <Trans>Report name</Trans>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ width: "inherit" }}>
                    <Dialog open={previewFile ? true : false} fullScreen>
                      <DialogTitle>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Trans>Document preview</Trans>
                          <IconButton
                            onClick={handlePreviewClose}
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            <CloseIcon></CloseIcon>
                          </IconButton>
                        </Box>
                      </DialogTitle>
                      <DialogContent dividers sx={{ textAlign: "center" }}>
                        {previewFile && previewFile.isImage() && (
                          <img
                            alt="preview"
                            title="preview"
                            src={blobUrl}
                            width="100%"
                          />
                        )}
                        {previewFile && previewFile.isPdf() && (
                          <object
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            type="application/pdf"
                            data={blobUrl}
                          >
                            <p>
                              Your web browser doesn't have a PDF plugin.
                              Instead you can{" "}
                              <b
                                style={{
                                  textDecoration: "underline",
                                }}
                                onClick={() => handleDownload(previewFile)}
                              >
                                click here to download the PDF file.
                              </b>
                            </p>
                          </object>
                        )}
                      </DialogContent>
                    </Dialog>
                    {items
                      /*.filter((item: any, index: number) => index === 0)*/
                      .map((item) => (
                        <ReportRowItem
                          key={item.id}
                          isSelected={selectedItems.some(
                            (id) => id === item.id,
                          )}
                          item={item}
                          cant_delete={cant_delete}
                          toggleSelect={toggleSelectSingleItem}
                          handleDownload={handleDownload}
                          handleDelete={handleDelete}
                          handleView={handleView}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Wrapper>
        )}
      </Box>
    </Box>
  );
};

export default memo(ReportUploadOrganism);
