import React, { useEffect } from "react";
import { Grid, Box, Alert } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { t, Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import { IFormCreateValues } from "./interface";
import createSchema from "./validation";
import { FormProvider, useForm } from "react-hook-form";
import SwitchMolecule from "@molecules/Switch";
import JobsService from "@services/JobsService";
import ClientsService from "@services/ClientsService";
import { IDS } from "@utils/constants";
import { CoreResponseModel } from "@core/models/Response.model";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import { toast } from "react-toastify";
import ButtonAtom from "@atoms/Button";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";

const CreateJobOrganism = ({ job, onSave }: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error /*, setError*/] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const formMethodsCreate = useForm<IFormCreateValues>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createSchema()),
  });

  const {
    reset,
    getValues: getValuesCreate,
    watch: watchCreate,
    resetField: resetFieldCreate,
    setValue: setValueCreate,
    formState: formStateCrate,
  } = formMethodsCreate;

  useEffect(() => {
    reset({
      client_id: job ? job.client.id : 0,
      type_id: job ? job.type.id : "",
      category_id: job ? job.category.id : "",
      notes: "",
      // code_id: job ? job.code_id : null,
      label: job ? job.label : "",
    });
  }, [job]);

  const isNew = watchCreate("is_new");

  const createJob = async () => {
    setIsLoading(true);
    let isNew = getValuesCreate().is_new;
    let newClientId = null;
    if (isNew) {
      const clientData = (await ClientsService.create({
        type_id: IDS.CUSTOMERS.CLIENT,
        country_id: getValuesCreate().client_country,
        name: getValuesCreate().client_name,
        email: getValuesCreate().client_email,
      })) as CoreResponseModel;
      newClientId = clientData.getData().id;
      if (!clientData.hasErrors()) {
        dispatch(fetchFilter("clients"));
        setValueCreate("client_id", newClientId);
        resetFieldCreate("client_country");
        resetFieldCreate("client_name");
        resetFieldCreate("client_email");
        setValueCreate("is_new", false);
      } else {
        toast(clientData.getMsgString(), { type: "error" });
      }
    }
    const jobValues = getValuesCreate();
    if (isNew) {
      jobValues.client_id = newClientId;
    }
    (!job
      ? JobsService.create(jobValues)
      : JobsService.put(jobValues, job.id)
    ).then((res: any) => {
      setIsLoading(false);
      if (!res.hasErrors()) {
        toast(res.getMsgString(), { type: "success" });
        if (onSave) {
          onSave(res.getData());
        }
      } else {
        toast(res.getMsgString(), { type: "error" });
      }
    });
  };

  const a = watchCreate('client_id');

  return (
    <Box sx={{ width: "100%" }}>
      {/*
       * TAKE CARE:
       * If you modify this form you will have to modify the @organism/InspectionCreate/LinkJob/index.tsx as well
       */}
      <Box>
        {
          <form noValidate>
            <FormProvider {...formMethodsCreate}>
              <Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {!isNew && (
                      <Grid item md={6} sm={6}>
                        <AutocompleteMolecule
                          control={formMethodsCreate.control}
                          controlName="client_id"
                          emptyValue={0}
                          optionValue={"id"}
                          variant={"outlined"}
                          label={
                            <React.Fragment>
                              <Trans>Client</Trans> *
                            </React.Fragment>
                          }
                          storeCollection="clients"
                          getOptionLabel={(client: any) => `${client.name}`}
                          optionLabel={"name"}
                        ></AutocompleteMolecule>
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={3} sm={3}>
                        <TextFieldAtom
                          controlName={"client_name"}
                          required={true}
                          variant={"outlined"}
                          label={<Trans>Client name</Trans>}
                        />
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={4} sm={4}>
                        <TextFieldAtom
                          controlName={"client_email"}
                          required={true}
                          variant={"outlined"}
                          label={<Trans>Client email</Trans>}
                        />
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={3} sm={3}>
                        <SelectMolecule
                          control={formMethodsCreate.control}
                          controlName="client_country"
                          emptyValue={0}
                          required={true}
                          optionValue={"id"}
                          variant={"outlined"}
                          label={t`Country`}
                          storeCollection="countries"
                          optionLabel={"name"}
                        ></SelectMolecule>
                      </Grid>
                    )}
                    <Grid item md={isNew ? 2 : 6} sm={isNew ? 2 : 6}>
                      <SwitchMolecule
                        controlName={"is_new"}
                        control={formMethodsCreate.control}
                        label={"Is new?"}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6}>
                          <SelectMolecule
                            control={formMethodsCreate.control}
                            controlName={"type_id"}
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            required={true}
                            label={<Trans>Division</Trans>}
                            storeCollection="types.jobs"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                        <Grid item md={6} sm={6}>
                          <SelectMolecule
                            control={formMethodsCreate.control}
                            controlName={"category_id"}
                            emptyValue={null}
                            required={true}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={<Trans>Service</Trans>}
                            storeCollection="categories.jobs"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} sm={6}></Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                      <TextFieldAtom
                        controlName={"label"}
                        minRows={2}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Project name</Trans>}
                      />
                    </Grid>
                    {/*<Grid item md={6} sm={6}>
											<TextFieldAtom
												controlName={"code_id"}
												required={true}
												minRows={2}
												variant={"outlined"}
												label={<Trans>Code ID</Trans>}
											/>
										</Grid>*/}
                  </Grid>
                </Box>
                {!job && (
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12}>
                        <TextFieldAtom
                          controlName={"notes"}
                          multiline={true}
                          minRows={2}
                          variant={"outlined"}
                          label={<Trans>Notes</Trans>}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </FormProvider>
          </form>
        }
        {error && (
          <Box mt={2}>
            <Alert severity="error">
              <Trans>An error occurred, please check the fields</Trans>
            </Alert>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <ButtonAtom
            loading={isLoading}
            variant="contained"
            label={job ? t`Save` : t`Create`}
            onClick={createJob}
            disabled={!formStateCrate.isValid}
            sx={{ mr: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateJobOrganism;
