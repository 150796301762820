import * as React from "react";
import { Alert, Box, Button } from "@mui/material";
import IComponentProps from "./interface";
import { UseFormReturn } from "react-hook-form";
import InspectionDetailFormOrganism from "@organisms/InspectionDetailForm";
import { InspectionModel } from "@models/InspectionModel";
import { IFormValues } from "@organisms/InspectionDetailForm/interface";
import { t, Trans } from "@lingui/macro";
import { useMutation } from "react-query";
import InspectionsService from "@services/InspectionsService";
import ButtonAtom from "@atoms/Button";
import ClientsService from "@services/ClientsService";
import { IDS } from "@utils/constants";
import { useAppDispatch } from "@stores/hooks";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { toast } from "react-toastify";

const InspectionDataForm = ({
  mode,
  jobId,
  onBack,
  onNext,
  onInspection,
}: IComponentProps) => {
  const [formInstance, setFormInstance] = React.useState<UseFormReturn<
    IFormValues,
    any
  > | null>(null);
  const [formIsValid, setFormIsValid] = React.useState<boolean>(true);
  const [vendor, setVendor] = React.useState<number | null>(null);
  const [previousId, setPreviousId] = React.useState<number | null>(null);
  const [errors, setError] = React.useState<boolean>(false);
  const [sErrors, setSErrors] = React.useState<any>([]);
  const dispatch = useAppDispatch();

  const formChangeListener = (form: UseFormReturn<IFormValues, any>) => {
    setFormIsValid(form.formState.isValid);
    setFormInstance(form);
  };

  const { mutate: createMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (!!previousId
        ? InspectionsService.put(formValues, String(previousId))
        : InspectionsService.create(formValues)
      ).then((res: any) => {
        if (!res.hasErrors()) {
          setPreviousId(res.getData().id);
          if (onNext) {
            onNext();
          }
          if (onInspection) {
            onInspection(res.getData());
          }
        } else {
          setError(true);
        }
      }),
  );

  const handleSubmit = () => {
    setError(false);
    if (formInstance) {
      const data = formInstance?.getValues();
      const values = { ...data };
      if (data.new_vendor) {
        ClientsService.create({
          type_id: IDS.ROLES.VENDOR,
          name: data.vendor_name,
          email: data.vendor_email,
          country_id: data.vendor_country,
          division: data.vendor_division,
        }).then((res: any) => {
          if (!res.hasErrors()) {
            dispatch(fetchFilter("vendors"));
            setVendor(res.getData().id);
            values.labels = values.labels.map((label: any) => label.id);
            values.po = values.po.map((label: any) => label.id);
            createMutation({
              ...values,
              ...{ job_id: jobId, vendor_id: res.getData().id },
            });
          } else {
            setSErrors(res.getErrors());
            toast(t`Unable to create vendor. Check the fields`, {
              type: "error",
            });
          }
        });
      } else {
        values.labels = values.labels.map((label: any) => label.id);
        values.po = values.po.map((label: any) => label.id);
        createMutation({ ...values, ...{ job_id: jobId } });
      }
    }
  };

  return (
    <Box>
      <InspectionDetailFormOrganism
        propagateDirtyStatus={mode !== "fast"}
        defaultVendor={vendor}
        inSErrors={sErrors}
        formListener={formChangeListener}
        inspection={new InspectionModel({})}
      />
      {errors && (
        <Box mt={2}>
          <Alert severity="error">
            <Trans>An error occurred, please check the fields</Trans>
          </Alert>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {mode !== "fast" && (
          <Button color="inherit" onClick={onBack} sx={{ mr: 1 }}>
            <Trans>Back</Trans>
          </Button>
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        <ButtonAtom
          variant="contained"
          loading={isLoading}
          label={t`Next`}
          onClick={() => handleSubmit()}
          disabled={!formIsValid}
          sx={{ mr: 1 }}
        />
      </Box>
    </Box>
  );
};

export default InspectionDataForm;
