import React, { Fragment, useEffect } from "react";
import { Grid, Box, Button, Alert, FormLabel } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { t, Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import IInspectionCreateOrganism, {
  IFormValues,
  setDefaultData,
} from "./interface";
import createSchema from "./validation";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SwitchMolecule from "@molecules/Switch";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";
import { IDS } from "@utils/constants";
import { UserModel } from "@models/UserModel";
import LabelsService from "@services/LabelsService";
import NotificationsAutocompleteMolecule from "./AsyncAutocomplete";
import InspectionsService from "@services/InspectionsService";
import SpinnerAtom from "@atoms/Spinner";
import RadioGroupMolecule from "@molecules/RadioGroup";
import HelperErrorMolecule from "@molecules/HelperError";
import ClientsService from "@services/ClientsService";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import GoogleAutocomplete from "@molecules/GoogleAutocomplete";
import { addForm, updateForm } from "@stores/reducers/saverReducer";
import AsyncSelectMolecule from "@molecules/AsyncSelect";
import { useSelector } from "react-redux";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const InspectionDetailFormOrganism = ({
  mode,
  defaultVendor,
  inSErrors,
  onDirtyChange,
  inspection,
  onSave,
  formListener,
  propagateDirtyStatus,
}: IInspectionCreateOrganism) => {
  const [sErrors, setSErrors] = React.useState(inSErrors ? inSErrors : null);
  const [msg, setMsg] = React.useState("");
  const [success, setSuccess] = React.useState<boolean>(false);
  const [partnersList, setPartnersList] = React.useState<Array<UserModel>>([]);
  const [renderKey, setRenderKey] = React.useState<number>(0);
  const [notCreatedPO, setNotCreatedPO] = React.useState<boolean>(false);
  const [notCreatedNotification, setNotCreatedNotification] =
    React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const userFilters = useSelector((state: any) => state.filters.filters.users);
  const inspectionActivitiesCategoriesFilters = useSelector((state: any) => state.filters.filters.categories?.activities);
  const autoSave = useSelector((state: any) => state.saver.saveAll);

  const formInstance = useForm<IFormValues>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: setDefaultData(inspection, defaultVendor, inspectionActivitiesCategoriesFilters),
    resolver: yupResolver(createSchema),
  });

  const { mutate: createActivityMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      InspectionsService.put(formValues, inspection.id).then((res: any) => {
        if (!res.hasErrors()) {
          if (mode !== "patch") {
            setSuccess(true);
            setMsg(res.getMsgString());
          }
          if (onDirtyChange) {
            onDirtyChange(false);
          }
          reset(setDefaultData(res.getData(), defaultVendor, inspectionActivitiesCategoriesFilters));
          toast(res.getMsgString(), {
            type: "success",
          });
          dispatch(
            updateForm({
              slug: "inspection-detail",
              dirty: false,
              saved: true,
            }),
          );
          if (onSave) {
            onSave(res.getData());
          }
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const {
    watch,
    control,
    setValue,
    reset,
    getValues,
    formState: { isValid, isDirty },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const watched = watch();

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(isDirty);
    }
    if (propagateDirtyStatus) {
      if (isDirty) {
        dispatch(
          addForm({
            slug: "inspection-detail",
            formState: formInstance.formState,
          }),
        );
      } else {
        dispatch(
          updateForm({
            slug: "inspection-detail",
            dirty: false,
            saved: true,
          }),
        );
      }
    }
  }, [formInstance, isDirty]);

  useEffect(() => {
    if (autoSave.status && isDirty) {
      handleSubmit(getValues());
    }
  }, [autoSave, getValues]);

  const handleSetDirty = (
    what: string | undefined,
    status: boolean,
    whatB: string | undefined = undefined,
  ) => {
    if (what) {
      if (what === "po") {
        setNotCreatedPO(status);
      }
      if (what === "notifications") {
        setNotCreatedNotification(status);
      }
    } else {
      setValue("_dirty", true, { shouldDirty: true });
    }
    if (whatB) {
      if (whatB === "po") {
        setNotCreatedPO(false);
      }
      if (whatB === "notifications") {
        setNotCreatedNotification(false);
      }
    }
    // setValue('_dirty', true, {shouldDirty: true});
  };

  React.useEffect(() => {
    if (defaultVendor !== undefined && defaultVendor !== null) {
      setValue("new_vendor", false);
      setValue("vendor_id", defaultVendor);
    }
  }, [defaultVendor, setValue]);

  useEffect(() => {
    if (formListener) {
      formListener(formInstance);
    }
  }, [watched]);

  const different_user_invoice = watch("different_user_invoice");
  const subcontracting = watch("subcontracting");
  const new_vendor = watch("new_vendor");
  const category_id = Number(watch("category_id"));
  const user_id = Number(watch("user_id"));

  useEffect(() => {
    if (user_id) {
      if (!userFilters) {
        return;
      }
      const inspector = userFilters.find(
        (user: UserModel) => user.id === user_id,
      );
      if (inspector) {
        if (inspector.partners.length) {
          setPartnersList(inspector.partners?.filter((partner: any) => partner.category_id != 6));
        } else {
          setValue("category_id", 6);
          setPartnersList([]);
        }
      } else {
        setPartnersList([]);
        setValue("category_id", 6);
      }
    } else {
      setPartnersList([]);
      setValue("partner_id", null);
    }
    setRenderKey(renderKey + 1);
  }, [user_id]);

  useEffect(() => {
    if (!different_user_invoice) {
      setValue("user_invoice_id", null);
    }
    if (new_vendor) {
      setValue("vendor_id", null);
    }
  }, [different_user_invoice, setValue, new_vendor]);

  useEffect(() => {
    if (!subcontracting) {
      setValue("subcontractor_id", null);
    }
  }, [subcontracting, setValue]);

  const handleSubmit = (data: IFormValues) => {
    const values = { ...data };
    if (data.new_vendor) {
      ClientsService.create({
        type_id: IDS.ROLES.VENDOR,
        name: data.vendor_name,
        email: data.vendor_email,
        country_id: data.vendor_country,
        division: data.vendor_division,
      }).then((res: any) => {
        if (!res.hasErrors()) {
          updateForm({
            slug: "inspection-detail",
            dirty: false,
            saved: true,
          })
          dispatch(fetchFilter("vendors"));
          setValue("vendor_id", res.getData().id);
          values.vendor_id = res.getData().id;
          values.labels = values.labels.map((label: any) => label.id);
          values.po = values.po.map((label: any) => label.id);
          createActivityMutation(values);
        } else {
          setSErrors(res.getErrors());
          toast(t`Unable to create vendor. Check the fields`, {
            type: "error",
          });
        }
      });
    } else {
      values.labels = values.labels.map((label: any) => label.id);
      values.po = values.po.map((label: any) => label.id);
      if (!partnersList.length) {
        values.category_id = 6;
      }
      createActivityMutation(values);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        {/* Step 1 */}
        <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
          <FormProvider {...formInstance}>
            <Box>
              <FormLabel>
                <Trans>Report settings</Trans>
              </FormLabel>
              <Grid container spacing={1}>
                <Grid item md={6} xs={6}>
                  <RadioGroupMolecule
                    controlName={"type_id"}
                    control={control}
                    storeCollection={"types.calendars"}
                  />
                </Grid>
                <Grid item sm={12} md={6}></Grid>
              </Grid>
              {inspection && inspection.id ? (
                <Grid container mt={1} spacing={1}>
                  <Grid item md={6} xs={6}>
                    <SelectMolecule
                      control={control}
                      nullable={false}
                      controlName="status_id"
                      listId={"insp-activity-statutes"}
                      storeCollection="statuses.inspections"
                      variant={"outlined"}
                      optionValue={"id"}
                      optionLabel={"name"}
                      label={
                        <React.Fragment>
                          <Trans>Inspection results</Trans> *
                        </React.Fragment>
                      }
                    />
                  </Grid>
                  <Grid item sm={12} md={6}></Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid container mt={1} spacing={1}>
                <Grid item md={6} xs={6}>
                  <AutocompleteMolecule
                    control={control}
                    controlName="user_id"
                    listId={"insp-edit-users"}
                    storeCollection="users"
                    variant={"outlined"}
                    except={(user: UserModel) =>
                      user.role ? user.role.id !== IDS.ROLES.INSPECTOR : false
                    }
                    optionValue={"id"}
                    emptyValue={{ full_name: "" }}
                    getOptionLabel={(user: any) => `${user.full_name}`}
                    optionLabel={"name"}
                    label={
                      <React.Fragment>
                        <Trans>Inspector</Trans> *
                      </React.Fragment>
                    }
                  ></AutocompleteMolecule>
                </Grid>
                <Grid item sm={12} md={6}>
                  <SwitchMolecule
                    controlName={"different_user_invoice"}
                    control={control}
                    label={"Different in invoice?"}
                  />
                  {sErrors && sErrors["different_user_invoice"] ? (
                    <HelperErrorMolecule
                      message={sErrors["different_user_invoice"]}
                    />
                  ) : null}
                </Grid>
              </Grid>
              {
                <Grid
                  container
                  mt={1}
                  spacing={1}
                  sx={{
                    display: different_user_invoice ? "flex" : "none",
                  }}
                >
                  <Grid item sm={6} md={6}>
                    <AutocompleteMolecule
                      control={control}
                      controlName="user_invoice_id"
                      listId={"insp-edit-users-2"}
                      storeCollection="users"
                      variant={"outlined"}
                      except={(user: UserModel) =>
                        user.role?.id !== IDS.ROLES.INSPECTOR
                      }
                      optionValue={"id"}
                      emptyValue={{
                        full_name: "",
                        id: null,
                      }}
                      getOptionLabel={(user: any) => `${user.full_name}`}
                      optionLabel={"name"}
                      label={
                        <React.Fragment>
                          <Trans>Invoice inspector</Trans> *
                        </React.Fragment>
                      }
                    ></AutocompleteMolecule>
                  </Grid>
                  <Grid item sm={6} md={6}></Grid>
                </Grid>
              }
              <Grid container mt={1} spacing={1}>
                <Grid item sm={12} md={6}>
                  <SelectMolecule
                    control={control}
                    controlName={"category_id"}
                    listId={"inspection-create-sources"}
                    storeCollection="categories.inspections"
                    except={(item: any) =>
                      item.alias === "provider" && !partnersList.length
                    }
                    variant={"outlined"}
                    emptyValue={null}
                    required={true}
                    optionValue={"id"}
                    optionLabel={"name"}
                    label={<Trans>Source</Trans>}
                  ></SelectMolecule>
                </Grid>
              </Grid>
              {category_id === 5 && user_id && partnersList.length ? (
                <Grid container mt={1} spacing={1} key={renderKey}>
                  <Grid item md={6}>
                    <AsyncSelectMolecule
                      control={control}
                      controlName={"partner_id"}
                      listId={"inspection-create-partners"}
                      variant={"outlined"}
                      filters={{ data: partnersList }}
                      emptyValue={null}
                      optionValue={"partner_id"}
                      required={true}
                      optionLabel={(item: any) => item?.partner?.name}
                      label={<Trans>Partner</Trans>}
                    ></AsyncSelectMolecule>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item sm={12} md={6}>
                    <Box
                      sx={{
                        display: !new_vendor ? "block" : "none",
                      }}
                    >
                      <AutocompleteMolecule
                        control={control}
                        controlName="vendor_id"
                        listId={"activities-filter-vendors"}
                        storeCollection="vendors"
                        emptyValue={{ name: "" }}
                        variant={"outlined"}
                        optionValue={"id"}
                        getOptionLabel={(user: any) => `${user.name}`}
                        optionLabel={"name"}
                        label={
                          <Fragment>
                            <Trans>Vendor</Trans> *
                          </Fragment>
                        }
                      ></AutocompleteMolecule>
                      <Box mt={2}>
                        <AutocompleteMolecule
                          control={control}
                          controlName="subvendor_id"
                          listId={"activities-filter-subvendors"}
                          storeCollection="vendors"
                          emptyValue={null}
                          variant={"outlined"}
                          optionValue={"id"}
                          getOptionLabel={(user: any) => `${user.name}`}
                          optionLabel={"name"}
                          label={
                            <Fragment>
                              <Trans>Subvendor</Trans>
                            </Fragment>
                          }
                        ></AutocompleteMolecule>
                      </Box>
                      {/*
											<SelectMolecule
												control={control}
												controlName="vendor_id"
												listId={'activities-filter-vendors'}
												storeCollection="vendors"
												required={true}
												variant={'outlined'}
												optionValue={'id'}
												optionLabel={'name'}
												label={<Trans>Vendor</Trans>}
											></SelectMolecule>
											 */}
                    </Box>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <SwitchMolecule
                      controlName={"new_vendor"}
                      control={control}
                      label={"New vendor?"}
                    />
                    {sErrors && sErrors["new_vendor"] ? (
                      <HelperErrorMolecule message={sErrors["new_vendor"]} />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1} sx={{ display: new_vendor ? "block" : "none" }}>
                <FormLabel>Create new vendor</FormLabel>
                <Grid container spacing={1}>
                  <Grid item sm={12} md={3}>
                    <TextFieldAtom
                      required={true}
                      controlName={"vendor_name"}
                      variant={"outlined"}
                      label={<Trans>Vendor name</Trans>}
                    />
                    {sErrors && sErrors["vendor_name"] ? (
                      <HelperErrorMolecule message={sErrors["vendor_name"]} />
                    ) : null}
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <TextFieldAtom
                      required={true}
                      controlName={"vendor_email"}
                      variant={"outlined"}
                      label={<Trans>Vendor email</Trans>}
                    />
                    {sErrors && sErrors["vendor_email"] ? (
                      <HelperErrorMolecule message={sErrors["vendor_email"]} />
                    ) : null}
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <AutocompleteMolecule
                      control={control}
                      controlName="vendor_country"
                      sError={sErrors ? sErrors["vendor_country"] : undefined}
                      listId={"insp-edit-countries"}
                      storeCollection="countries"
                      variant={"outlined"}
                      optionValue={"id"}
                      emptyValue={{ name: "" }}
                      getOptionLabel={(item: any) => `${item.name}`}
                      optionLabel={"name"}
                      label={
                        <React.Fragment>
                          <Trans>Vendor country</Trans> *
                        </React.Fragment>
                      }
                    ></AutocompleteMolecule>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <TextFieldAtom
                      controlName={"vendor_division"}
                      variant={"outlined"}
                      label={<Trans>Vendor division</Trans>}
                    />
                    {sErrors && sErrors["vendor_division"] ? (
                      <HelperErrorMolecule
                        message={sErrors["vendor_division"]}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid container mt={1} spacing={1}>
                  <Grid item sm={12} md={6}>
                    <SwitchMolecule
                      controlName={"subcontracting"}
                      control={control}
                      label={"Subcontracting?"}
                    />
                    {sErrors && sErrors["subcontracting"] ? (
                      <HelperErrorMolecule
                        message={sErrors["subcontracting"]}
                      />
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    sx={{
                      display: subcontracting ? "block" : "none",
                    }}
                  >
                    <AutocompleteMolecule
                      control={control}
                      controlName="subcontractor_id"
                      sError={sErrors ? sErrors["subcontractor_id"] : undefined}
                      listId={"insp-edit-subcontractor"}
                      storeCollection="subcontractors"
                      variant={"outlined"}
                      optionValue={"id"}
                      emptyValue={{ name: "", id: null }}
                      getOptionLabel={(user: any) => `${user.name}`}
                      optionLabel={"name"}
                      label={
                        <React.Fragment>
                          <Trans>Subcontractor</Trans> *
                        </React.Fragment>
                      }
                    ></AutocompleteMolecule>
                  </Grid>
                </Grid>
                <Grid container mt={1} spacing={1}>
                  <Grid item md={6}>
                  <SelectMolecule
                      control={control}
                      nullable={false}
                      controlName="activity_category_id"
                      listId={"insp-creation-categories-activities"}
                      storeCollection="categories.activities"
                      variant={"outlined"}
                      optionValue={"id"}
                      optionLabel={"name"}
                      label={
                        <React.Fragment>
                          <Trans>Inspection category</Trans> *
                        </React.Fragment>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box mt={2}>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <NotificationsAutocompleteMolecule
                    control={control}
                    onWrite={(string: string) =>
                      handleSetDirty("notifications", string ? true : false)
                    }
                    defaultValue={inspection.labels}
                    controlName="labels"
                    onCreate={() =>
                      handleSetDirty(undefined, true, "notifications")
                    }
                    freeSolo={true}
                    emptyValue={""}
                    sError={sErrors ? sErrors["labels"] : undefined}
                    multiple={true}
                    typeId={33}
                    getOptionLabel={(item: any) =>
                      `${item.name ? item.name : item}`
                    }
                    listId={"insp-edit-labels"}
                    service={LabelsService}
                    filters={{
                      data: inspection.labels ? inspection.labels : [],
                    }}
                    variant={"outlined"}
                    optionValue={"id"}
                    label={<Trans>Notifications</Trans>}
                  />
                  {notCreatedNotification && (
                    <Alert icon={<WarningAmberIcon />} color="error">
                      Press ENTER in order to create the Notification
                    </Alert>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <NotificationsAutocompleteMolecule
                    control={control}
                    defaultValue={inspection.po}
                    onWrite={(string: string) =>
                      handleSetDirty("po", string ? true : false)
                    }
                    onCreate={() => handleSetDirty(undefined, true, "po")}
                    controlName="po"
                    freeSolo={true}
                    typeId={34}
                    emptyValue={""}
                    sError={sErrors ? sErrors["po"] : undefined}
                    multiple={true}
                    getOptionLabel={(item: any) =>
                      `${item.name ? item.name : item}`
                    }
                    listId={"insp-edit-po"}
                    service={LabelsService}
                    filters={{
                      data: inspection.po ? inspection.po : [],
                    }}
                    variant={"outlined"}
                    optionValue={"id"}
                    label={<Trans>PO</Trans>}
                  />
                  {notCreatedPO && (
                    <Alert icon={<WarningAmberIcon />} color="error">
                      Press ENTER in order to create the PO
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Grid container mt={1} spacing={1}>
              <Grid item md={12} xs={12}>
                <TextFieldAtom
                  type={"text"}
                  controlName={"project_label"}
                  variant={"outlined"}
                  helperText={sErrors ? sErrors["project_label"]?.message : undefined}
                  label={<Trans>Project label</Trans>}
                />
              </Grid>
            </Grid>
            <Grid container mt={1} spacing={1}>
              <Grid item md={6} xs={6}>
                <AutocompleteMolecule
                  control={control}
                  controlName="country_id"
                  sError={sErrors ? sErrors["country_id"] : undefined}
                  listId={"insp-edit-ccc"}
                  storeCollection="countries"
                  variant={"outlined"}
                  optionValue={"id"}
                  emptyValue={{ name: "" }}
                  getOptionLabel={(item: any) => `${item.name}`}
                  optionLabel={"name"}
                  label={<Trans>C&zwnj;ountry</Trans>}
                ></AutocompleteMolecule>
              </Grid>
              <Grid item md={6} xs={6}>
                <GoogleAutocomplete
                  control={control}
                  label={<Trans>A&zwnj;ddress</Trans>}
                  controlName={"street"}
                />
                {/*<TextFieldAtom
									required={true}
									controlName={'street'}
									variant={'outlined'}
								label={<Trans>Address</Trans>}/>*/}
                {sErrors && sErrors["street"] ? (
                  <HelperErrorMolecule message={sErrors["street"]} />
                ) : null}
              </Grid>
            </Grid>
            {success && (
              <React.Fragment>
                {msg ? (
                  <Box mt={2}>
                    <Alert severity="success">{msg}</Alert>
                  </Box>
                ) : null}
              </React.Fragment>
            )}
            {!formListener && (
              <Box mt={1} className="text-right">
                <Button type="submit" disabled={!isValid} variant="contained">
                  {isLoading && <SpinnerAtom />}
                  {!isLoading && <Trans>Save</Trans>}
                </Button>
              </Box>
            )}
          </FormProvider>
        </form>
      </React.Fragment>
    </Box>
  );
};

export default InspectionDetailFormOrganism;
