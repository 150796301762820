import { useEffect, Fragment } from "react";
import { Box, Grid, Paper, Card, CardContent, Typography } from "@mui/material";
import { ActivityModel } from "@models/ActivityModel";
import ActivityDetailResumeOrganism from "@organisms/ActivityDetail/Details/InspectorVersion";
import { set } from "@stores/reducers/titleReducer";
import { useDispatch } from "react-redux";
import NotesOrganism from "@organisms/Notes";
import EvaluationOrganism from "@organisms/Evaluation";
import ReportUpload from "@organisms/ReportUpload";

interface IDetailsPage {
  activity: ActivityModel;
}

const InspectorActivityDetailsPage = ({ activity }: IDetailsPage) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set("Activity"));
  }, [dispatch]);

  return (
    <>
      <Box sx={{ marginBottom: "150px" }}>
        {
          <Fragment>
            <Box>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <Paper sx={{ padding: "15px" }}>
                    {/* ! inspector version */}
                    <ActivityDetailResumeOrganism
                      activity={activity}
                      verbose={["asset.make"]}
                      hide={["status", "owner", "purchase", "partner"]}
                    />
                  </Paper>
                </Grid>
                <Grid item md={6} xs={12}>
                  {activity.status && activity.status.alias === "checkout" && (
                    <Card sx={{ marginTop: "10px" }}>
                      <CardContent>
                        <EvaluationOrganism activity={activity} />
                      </CardContent>
                    </Card>
                  )}
                  {activity.status.alias === "checkout" && (activity.inspection.isDaily() || (activity.inspection.isUnique() && activity.isLast())) ?
                  (
                    <Box className="text-center">
                      <Box mt={1} mb={1}>
                        <ReportUpload
                          allow_multiple={true}
                          model_id={activity.id}
                          model_type="activities"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Card>
                    <CardContent>
                      <Typography>
                        <b>Notes</b>
                      </Typography>
                      <NotesOrganism
                        hide_crud={true}
                        model_id={activity.inspection_id}
                        model_type={"inspections"}
                      ></NotesOrganism>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        }
      </Box>
    </>
  );
};

export default InspectorActivityDetailsPage;
