import { CoreBaseModel } from "@core/models/Base.model";
import StatusLogModel from "@models/StatusLogModel";
import { InspectionModel } from "./InspectionModel";
import moment from "moment";

export class ActivityModel extends CoreBaseModel {
  date: string;
  asset: any;
  asset_id: number;
  client_job_id: string;
  created_at: string;
  customer: any;
  category: any;
  category_id: number;
  days: number;
  month: number;
  year: number;
  customer_id: number;
  start_time: string;
  end_time: string;
  status_logs: Array<StatusLogModel>;
  last_remind_at: string;
  vendor?: any;
  inspection_id: number;
  id: number;
  is_purchase: boolean;
  rel_activities: Array<any>;
  address: any;
  full_address: string;
  inspection: any;
  report_status: any;
  job_id: string;
  user: any;
  partner: any;
  partner_id: number;
  type: any;
  type_id: number;
  status: any;
  status_id: number;
  updated_at: string;
  user_id: number;
  next_status: {
    class:
      | "default"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning"
      | undefined;
    id: number;
    name: string;
    evaluation: boolean;
    alias: string;
  };
  model_type: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    this.model_type = "activities";
  }

  static getResourceName() {
    return "activities";
  }

  static getSubTypesList() {
    return [
      { object: "status_logs", model: StatusLogModel },
      { object: "inspection", model: InspectionModel },
    ];
  }

  readableDate(format = "dd/mm/yy") {
    const date = new Date(this.date);
    format = format.replace("dd", String(date.getDate()));
    format = format.replace("mm", String(date.getMonth()));
    format = format.replace("yy", String(date.getFullYear()));
    return format;
  }

  checkedIn() {
    return this.status.alias === "checkin";
  }

  lastCheckin(what: "created_at" | "id"): string | number | undefined {
    const log = this.status_logs.find(
      (log: StatusLogModel) => log.status.alias === "checkin",
    );
    if (what && log) {
      return log[what];
    } else {
      return undefined;
    }
  }

  nextStatus() {
    return this.next_status;
  }

  totalRelActivities() {
    return this.rel_activities.length;
  }

  startTime() {
    return this.start_time.split(":", 2).join(":");
  }

  endTime() {
    return this.end_time.split(":", 2).join(":");
  }

  notifications(as: string = "", truncate: number = 0) {
    if (as === "string") {
      if (this.inspection && this.inspection.labels) {
        const output = this.inspection.labels
          .map((label: any) => label.name)
          .join(", ");
        if (truncate) {
          return output.length > truncate
            ? output.substring(0, truncate) + "..."
            : output;
        } else {
          return output;
        }
      } else {
        return "";
      }
    } else {
      return this.inspection
        ? this.inspection.labels
          ? this.inspection.labels
          : []
        : [];
    }
  }

  isLast() {
    let date_list = this.rel_activities.map((a: ActivityModel) => a.date);
    let moments = date_list.map((d) => moment(d));
    let maxDate = moment.max(moments);
    let maxDateReadable: string = "";
    if (maxDate) {
      maxDateReadable = maxDate.format("YYYY-MM-DD");
    }
    if (maxDateReadable) {
      return this.date === maxDateReadable;
    } else {
      return false;
    }
  }

  getUser() {
    if (this.user_id) {
      return this.user;
    } else {
      return this.inspection.user;
    }
  }

  getAddress() {
    if (this.full_address) {
      return this.full_address;
    } else if (this.inspection.full_address) {
      return this.inspection.full_address;
    } else {
      return null;
    }
  }
}
