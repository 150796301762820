import { IResponse } from "@core/interfaces/IResponse";
import { CoreResultInstanceModel } from "./ResultInstance.model";

/**
 * Slug which refers to user data received at login
 * it is used as a key for storing user data after logging in
 */
export const USERDATA_SLUG = "userdata";

/**
 * Response Model
 * If the server's response is modified this model is the only file to modify (and ResponseInterface )
 */
export class CoreResponseModel implements IResponse {
  errors: any; // any[] | any
  data: any; // any
  headers: any; // any
  type: any; // string
  blob: any; // any
  status: any;
  request: any;
  msg: string;

  // Pagination
  perpage: number;
  current_page: number;
  total: number;
  last_page: number;

  constructor(response: any) {
    if (response) {
      if (response.headers["content-type"] === "application/json") {
        this.data = response.data.data ? response.data.data : {};
        // Pagination
        if (this.data.current_page) {
          this.current_page = this.data.current_page;
          this.last_page = this.data.last_page;
          this.total = this.data.total;
          this.last_page = this.data.last_page;
        }
      } else {
        this.blob = response.data;
      }
      this.headers = response.headers;
      this.status = response.status;
      this.request = response.request;
      this.msg = response.data?.msg;
      this.errors = response.data?.error;
    }
  }

  hasData() {
    const data = this.getData();
    return data
      ? Array.isArray(data)
        ? data.length
          ? true
          : false
        : false
      : false;
  }

  getData(forceArray = false, mapFilter = null) {
    let output: any;
    if (forceArray) {
      output = [];
      Object.keys(this.data).forEach((key) => {
        output.push(this.data[key]);
      });
      return output;
    }
    output = this.data;
    if (mapFilter && Array.isArray(output)) {
      output.map(mapFilter);
    }
    return output;
  }

  getDataModel(forceArray = false, mapFilter = null) {
    if (forceArray) {
      let data: Array<any> = [];
      Object.keys(this.data).forEach((key) => {
        data.push(this.data[key]);
      });
      return new CoreResultInstanceModel(data);
    }
    if (mapFilter && Array.isArray(this.data)) {
      this.data.map(mapFilter);
    }
    return new CoreResultInstanceModel(this.data);
  }

  logData() {
    console.log(this.data);
    return this;
  }

  hasErrors() {
    if (this.status !== 200) {
      return true;
    }
    if (Array.isArray(this.errors)) {
      return this.errors.length ? true : false;
    }
    for (const key in this.errors) {
      if (this.errors.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }

  getMsgString() {
    return this.msg;
  }

  getErrors() {
    return this.hasErrors() ? this.errors : null;
  }

  getHeaders() {
    return this.headers;
  }

  hasError(term: string) {
    const keys = Object.keys(this.errors);
    for (const key in keys) {
      if (key === term) {
        return this.errors[key];
      }
    }
    return null;
  }

  download(fileName: string) {
    if (this.blob) {
      const dataType = this.headers["content-type"];
      const binaryData: Array<any> = [];
      binaryData.push(this.blob);
      const downloadLink = document.createElement("a");
      const url = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType }),
      );
      downloadLink.href = url;
      downloadLink.setAttribute("download", fileName);
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error("ResponseModel.download: no blob available");
    }
  }
}
