import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Typography,
  Card,
  Grid,
  CardContent,
  Button,
  Tooltip,
} from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import DocumentsOrganism from "@organisms/Documents";
import IPeopleDetailOrganism from "./interface";
import { t, Trans } from "@lingui/macro";
import TabsMolecule from "@molecules/Tabs";
import { PeopleModel } from "@models/PeopleModel";
import PeopleService from "@services/PeopleService";
import PeopleDetailQualificationsOrganism from "@organisms/PeopleDetail/Qualifications";
import EmailIcon from "@mui/icons-material/Email";
import { toast } from "react-toastify";
import PeopleDetailJobsOrganism from "./Jobs";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { PersonModel } from "@models/PersonModel";
import Paper from "@mui/material/Paper";
import PeopleDetailDataOrganism from "./Data";

const PeopleDetailOrganism = ({ id, onUpdate }: IPeopleDetailOrganism) => {
  const [person, setResults] = useState<PeopleModel | undefined>(undefined);
  const [sendingPassword, setSendingPassword] = useState<boolean>(false);

  const { isLoading, data, refetch } = useQuery(
    `person-detail${id}`,
    () => PeopleService.get(id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const sendPassword = () => {
    setSendingPassword(true);
    PeopleService.sendPassword(id).then((res: any) => {
      setSendingPassword(false);
      toast(res.getMsgString(), {
        type: "success",
      });
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
    }
  }, [isLoading, data]);

  const assetDetailPanel = () => {
    return (
      <>
        <Box>
          <Typography variant="h2">
            <Trans>Inspector details</Trans>
          </Typography>
          <Card
            variant="outlined"
            elevation={0}
            sx={{ minWidth: 275, padding: 0 }}
          >
            <Grid container>
              <Grid item md={9}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "" }}>
                    <Typography
                      sx={{ marginBottom: 0 }}
                      variant="h5"
                      gutterBottom
                    >
                      {person?.full_name}
                    </Typography>
                    {person?.verified ? (
                      <Tooltip title={t`This person is verified`}>
                        <CheckCircleOutlinedIcon
                          sx={{
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                          fontSize="medium"
                          color="info"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t`This person is not verified`}>
                        <ErrorOutlinedIcon
                          sx={{
                            marginLeft: "10px",
                            marginTop: "4px",
                          }}
                          fontSize="medium"
                          color="warning"
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Grid container mt={2}>
                    <Grid item md={4}>
                      <b>
                        <Trans>Email</Trans>
                      </b>
                      <Typography variant="body2">{person?.email}</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <b>
                        <Trans>Phone</Trans>
                      </b>
                      <Typography variant="body2">
                        {person?.phone ? person.phone : "--"}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <b>
                        <Trans>Country/city</Trans>
                      </b>
                      <Typography variant="body2" color="text.secondary">
                        {person?.country ? person.country.name : "--"}
                        {person?.country && person?.city ? " - " : ""}
                        {person?.city ? person.city : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <Grid item md={3}>
                <CardContent>
                  <Tooltip title={t`Remember personal password to this user`}>
                    <Button
                      disabled={sendingPassword}
                      onClick={sendPassword}
                      variant="outlined"
                    >
                      <EmailIcon></EmailIcon>&nbsp;
                      <Trans>Send password</Trans>
                    </Button>
                  </Tooltip>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
          <TabsMolecule
            tabsLabels={[
              {
                label: "Qualifications",
                child: (
                  <Box mt={2}>
                    <PeopleDetailQualificationsOrganism person={person} />
                  </Box>
                ),
              },
              {
                label: "Data",
                child: (
                  <Box mt={2}>
                    <PeopleDetailDataOrganism
                      person={person}
                      onUpdate={(person: PersonModel) => {
                        refetch();
                        onUpdate(person);
                      }}
                    />
                  </Box>
                ),
              },
              {
                label: "Inspections",
                child: (
                  <Box mt={2}>
                    <PeopleDetailJobsOrganism person={person} />
                  </Box>
                ),
              },
              {
                label: "Docs",
                value: "week",
                child: (
                  <Paper variant="outlined" sx={{ marginTop: "15px" }}>
                    <Box>
                      <DocumentsOrganism model_id={id} model_type={"users"} />
                    </Box>
                  </Paper>
                ),
              },
            ]}
          />
        </Box>
      </>
    );
  };

  return <>{person ? assetDetailPanel() : <SpinnerAtom />}</>;
};

export default PeopleDetailOrganism;
