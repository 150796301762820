import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import IInspectionDetailOrganism from "./interface";
import {
  StyledInspectionInfoGrid,
  StyledInspectionStatusesGrid,
} from "./styled";
import { t, Trans } from "@lingui/macro";
import ChipAtom from "@atoms/Chip";
import InspectionsService from "@services/InspectionsService";
import { InspectionModel } from "@models/InspectionModel";
import TabsMolecule from "@molecules/Tabs";
import InspectionDetailTabOrganism from "./Details";
import InspectionDetailActTabOrganism from "./Activities";
import InspectionDetailQualityTabOrganism from "./Quality";
import { toast } from "react-toastify";
import DatePrintAtom from "@atoms/DatePrint";
import { Link } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import DataBalloonAtom from "@atoms/DataBalloon";
import { messageService } from "@helpers/messagesService";
import { useDispatch } from "react-redux";
import { resetFormsWarnings } from "@stores/reducers/saverReducer";

const InspectionDetailOrganism = ({
  id,
  activityId,
  onUpdate,
  onDelete,
  onCopy,
}: IInspectionDetailOrganism) => {
  const dispatch = useDispatch();
  const [inspection, setResults] = useState<InspectionModel | undefined>(
    undefined,
  );
  const confirm = useConfirm();

  useEffect(() => {
    dispatch(resetFormsWarnings());
  }, []);

  const { isLoading, data, refetch } = useQuery(
    `activity-detail${id}`,
    () => InspectionsService.get(id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    const sub = messageService.getMessage().subscribe((message) => {
      if (message.text === "inspectiondetail/refetch") {
        refetch();
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
      if (onUpdate) {
        onUpdate(data.data);
      }
    }
  }, [isLoading, data]);

  const copy = () => {
    InspectionsService.copyInspection(id).then((res: any) => {
      if (!res.hasErrors()) {
        if (onCopy) {
          // @ts-ignore
          onCopy(res.getData());
        }
        toast(res.getMsgString(), {
          type: "success",
        });
      } else {
        toast(res.getMsgString(), {
          type: "error",
        });
      }
    });
  };

  const deleteInspection = () => {
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      InspectionsService.delete(id).then((res: any) => {
        if (!res.hasErrors()) {
          if (onDelete) {
            // @ts-ignore
            onDelete();
          }
          toast(res.getMsgString(), {
            type: "success",
          });
        } else {
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      });
    });
  };

  const openJob = () => {
    // todo
  };

  const inspectionDetailPanel = () => {
    return (
      <>
        {inspection && (
          <Box mb={1}>
            <Typography variant="h2">
              <Trans>Inspection details</Trans>
            </Typography>
            <Card variant="outlined" elevation={0}>
              <CardContent>
                <Grid container spacing={2} sx={{ marginTop: "0px" }}>
                  <Grid
                    item
                    md={10}
                    sx={{
                      borderRight: "1px solid #DDDDDD",
                    }}
                  >
                    <Grid container>
                      <Grid item md={7}>
                        <Box>
                          <Typography color="text.secondary" gutterBottom>
                            {inspection.next_activity.type === "next" ? (
                              <Trans>Next activity</Trans>
                            ) : (
                              <Trans>Last activity</Trans>
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="text.secondary"
                            gutterBottom
                          >
                            {inspection.next_activity?.date ? (
                              <DatePrintAtom format="DD/MM/YYYY">
                                {inspection.next_activity?.date}
                              </DatePrintAtom>
                            ) : (
                              "--"
                            )}
                          </Typography>
                          <Box>
                            <DataBalloonAtom
                              variant="chip"
                              title={t`Total of activities`}
                              severity={"warning"}
                              data={inspection?.totalRelActivities()}
                            />
                          </Box>
                        </Box>
                        <StyledInspectionInfoGrid container>
                          <Grid item md={6}>
                            <Typography>
                              <b>Client:</b> {inspection.job?.client.name}
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography>
                              <b>Job:</b> {inspection.job?.code_id}
                            </Typography>
                            {inspection.job?.client_code_id && (
                              <Typography>
                                <b>Project name:</b>{" "}
                                {inspection.job?.client_code_id}
                              </Typography>
                            )}
                          </Grid>
                        </StyledInspectionInfoGrid>
                      </Grid>
                      <Grid item md={5} className="justify-vertically">
                        <StyledInspectionStatusesGrid container>
                          <Grid item md={6} className="align-center">
                            <Typography variant="body2">
                              Assignment confirmation
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography component="div">
                              {inspection.confirmation ? (
                                <ChipAtom
                                  variant="filled"
                                  size="small"
                                  label={"Confirmed"}
                                  color={"success"}
                                />
                              ) : (
                                <ChipAtom
                                  variant="filled"
                                  size="small"
                                  label={"Not confirmed"}
                                  color={"warning"}
                                />
                              )}
                            </Typography>
                          </Grid>
                        </StyledInspectionStatusesGrid>
                        <StyledInspectionStatusesGrid container>
                          <Grid item md={6} className="align-center">
                            <Typography variant="body2">
                              Next / last report
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography component="div">
                              {inspection.next_activity?.report_status ? (
                                <ChipAtom
                                  variant="filled"
                                  size="small"
                                  label={
                                    inspection.next_activity.report_status.name
                                  }
                                  color={
                                    inspection.next_activity.report_status.class
                                  }
                                />
                              ) : (
                                <ChipAtom
                                  variant="filled"
                                  size="small"
                                  label={"Undefined activity"}
                                  color={"warning"}
                                />
                              )}
                            </Typography>
                          </Grid>
                        </StyledInspectionStatusesGrid>
                        <StyledInspectionStatusesGrid container>
                          <Grid item md={6} className="align-center">
                            <Typography variant="body2">
                              Inspection status
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography component="div">
                              <ChipAtom
                                variant="filled"
                                size="small"
                                label={inspection.status.name}
                                color={inspection.status.class}
                              />
                            </Typography>
                          </Grid>
                        </StyledInspectionStatusesGrid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={2}>
                    <Box pb={1}>
                      <Link
                        to={`/jobs?id=${inspection.job_id}`}
                      >
                        <Button onClick={openJob} fullWidth variant="outlined">
                          <Trans>Open job</Trans>
                        </Button>
                      </Link>
                    </Box>
                    <Box pb={1}>
                      <Button onClick={copy} fullWidth variant="outlined">
                        <Trans>Copy</Trans>
                      </Button>
                    </Box>
                    <Box pb={1}>
                      <Button
                        onClick={deleteInspection}
                        fullWidth
                        variant="outlined"
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        {inspection && (
          <TabsMolecule
            preselected={0}
            tabsLabels={[
              {
                label: "Inspection",
                child: (
                  <InspectionDetailTabOrganism
                    onSave={(inspection: InspectionModel) => refetch()}
                    inspection={inspection}
                  />
                ),
              },
              {
                label: (
                  <Box>
                    <Trans>Activities</Trans>&nbsp;
                    <ChipAtom
                      size="small"
                      label={inspection?.totalRelActivities()}
                    />
                  </Box>
                ),
                child: (
                  <InspectionDetailActTabOrganism
                    activityId={activityId}
                    inspection={inspection}
                  />
                ),
              },
              {
                label: "Quality",
                child: (
                  <InspectionDetailQualityTabOrganism inspection={inspection} />
                ),
              },
            ]}
          />
        )}
      </>
    );
  };

  return <>{inspection ? inspectionDetailPanel() : <SpinnerAtom />}</>;
};

export default InspectionDetailOrganism;
