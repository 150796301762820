import { ActivityModel } from "@models/ActivityModel";
import { DateLimit, DateOption } from "flatpickr/dist/types/options";

export default interface IOrganism {
  activity: ActivityModel;
  saveFormKey?: number;
  onSave?: (param: any) => void;
  onDirty?: (param: boolean) => void;
  hideReportStatus?: boolean;
  exceptDates?: Array<DateLimit<DateOption>>;
}

export const defaultData = (activity: ActivityModel) => {
  return {
    report_status_id: activity.report_status ? activity.report_status.id : null,
    date: activity.date,
    start_time: `2020-01-01T${activity.start_time}`, //fake date
    end_time: `2020-01-01T${activity.end_time}`, // fake date
    street: activity.address ? activity.address.street : "",
  };
};
