import SpinnerAtom from "@atoms/Spinner";
import { Alert, Container } from "@mui/material";
import Box from "@mui/material/Box";
import InspectionsService from "@services/InspectionsService";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { StyledMainWrapper } from "./styled";
import { Trans } from "@lingui/macro";

const DownloadPage = () => {
  const [success, setSuccess] = useState<string | null>(null);
  const [failed, setFailed] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      InspectionsService.download(Number(id), searchParams.get('token') ? {
        'Authorization': 'Bearer ' + searchParams.get('token')
      } : undefined)
        .then((res: any) => {
          setLoading(false);
          if (!res.hasErrors()) {
            setSuccess("File received");
            res.download(`inspection-${id}`);
          } else {
            setFailed(res.getMsgString());
          }
        })
        .catch((e: any) => {
          setLoading(false);
          setFailed("Server error");
        });
    }
  }, []);

  return (
    <Container>
      <StyledMainWrapper>
        {loading && <Box>
          <SpinnerAtom size={"50px"} />
          <Box mt={6}>
          <Alert icon={false} color={"info"}>
            <Trans>Downloading file</Trans>...
          </Alert>
        </Box>
          </Box>}
        {success && (
          <Box>
            <Box>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "200px" }}
                color="success"
              />
            </Box>
            <Alert icon={false} color={"success"}>
              {success}
            </Alert>
          </Box>
        )}
        {failed && (
          <Box>
            <Box>
              <ErrorOutlineIcon sx={{ fontSize: "200px" }} color="error" />
            </Box>
            <Alert icon={false} color={"error"}>
              {failed}
            </Alert>
          </Box>
        )}
      </StyledMainWrapper>
    </Container>
  );
};

export default DownloadPage;
