import { memo, useEffect } from "react";
import { useAppDispatch } from "@stores/hooks";
import { fetchFilters } from "@stores/reducers/filtersReducer";
import { fetchMenu } from "@stores/reducers/menuReducers";

const PrivateMiddleLayer = (props: any) => {
  // Get filters
  const dispatch = useAppDispatch();

  // Run only once
  useEffect(() => {
    dispatch(
      fetchFilters({
        resources: [
          "countries",
          "categories",
          "partners",
          "vendors",
          "labels",
          "roles",
          "users",
          "types",
          "clients",
          "subcontractors",
          "partners",
          "statuses",
        ],
      }),
    );
    dispatch(fetchMenu({}));
  }, []);

  return <>{props.children}</>;
};

export default memo(PrivateMiddleLayer);
