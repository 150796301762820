import SpinnerAtom from "@atoms/Spinner";
import { Alert, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InspectionsService from "@services/InspectionsService";
import { useEffect, useState, Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { StyledMainWrapper } from "./styled";
import { InspectionModel } from "@models/InspectionModel";
import ReportUpload from "@organisms/ReportUpload";
import { FormProvider, useForm } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ActivitiesService from "@services/ActivitiesService";
import { ActivityModel } from "@models/ActivityModel";
import AsyncSelectMolecule from "@molecules/AsyncSelect";

const createSchema = Yup.object().shape({
  activity_id: Yup.number()
});


const UploadPage = () => {
  const [inspection, setInspection] = useState<InspectionModel | undefined>(undefined);
  const [activities, setActivities] = useState<Array<ActivityModel>>([]);
  const [selectedActivity, setSelectedActivity] = useState<ActivityModel | undefined>(undefined);
  const [failed, setFailed] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setLoading(true);
      InspectionsService.get(Number(id), undefined, false, false, undefined, searchParams.get('token') ? {
        'Authorization': 'Bearer ' + searchParams.get('token')
      } : undefined)
        .then((res: any) => {
          setLoading(false);
          if (!res.hasErrors()) {
            setInspection(res.getData());
          } else {
            setFailed(res.getMsgString());
          }
        })
        .catch((e: any) => {
          setLoading(false);
          setFailed("Server error");
        });
    }
  }, []);

  const formInstance = useForm<any>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      activity_id: null
    },
    resolver: yupResolver(createSchema),
  });

  const {
    control,
    watch,
    setValue,
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const handleSubmit = (data: any) => {
    // nothing...
  };

  const selectedActivityId = watch('activity_id');

  useEffect(() => {
    if(inspection) {
      ActivitiesService.filters(inspection.id, searchParams.get('token') ? {
        'Authorization': 'Bearer ' + searchParams.get('token')
      } : undefined).then((res: any) => {
        if(inspection?.type.alias === 'unique') {
          const last_activity = res.getData().activities.filter((activity: ActivityModel) => {
            const aModel = new ActivityModel(activity);
            aModel.rel_activities = res.getData().activities;
            return aModel.isLast();
          });
          setActivities(last_activity);
          setValue('activity_id', last_activity[0].id, {shouldValidate: true});
        } else {
          setActivities(res.getData().activities);
        }
      })
    }
  }, [inspection]);

  useEffect(() => {
    if(selectedActivityId) {
      setSelectedActivity(activities.find((activity: ActivityModel) => activity.id === selectedActivityId));
    }
  }, [selectedActivityId])

  return (
    <Container>
      <StyledMainWrapper>
        {loading && <SpinnerAtom size={"50px"} />}
        {inspection && (
          <Box>
            <Typography variant="h2">
              <Trans>Upload report</Trans>&nbsp;
            </Typography>
            <Box mb={3} sx={{textAlign: 'left'}}>
              <Typography>
                <b><Trans>Project name</Trans></b>: { inspection?.job.label }
              </Typography>
              <Typography>
                <b><Trans>Category</Trans></b>: { inspection?.activity_category.name }
              </Typography>
              <Typography>
                <b><Trans>Client</Trans></b>: { inspection?.job.client.name }
              </Typography>
              <Typography>
                <b><Trans>Job</Trans></b>: { inspection?.job.code_id }
              </Typography>
              <Typography>
                <b><Trans>Vendor</Trans></b>: { inspection?.vendor.name }
              </Typography>
              <Typography>
                <b><Trans>Full address</Trans></b>: { inspection?.full_address }
              </Typography>
            </Box>
            <Box>
              {
                activities.length && !uploaded ?
                <Box>
                  <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                    <FormProvider {...formInstance}>
                      <AsyncSelectMolecule
                        control={control}
                        nullable={false}
                        controlName="activity_id"
                        listId={"insp-activity-upload-report"}
                        filters={{
                          data: activities.map((activity: ActivityModel) => {
                            return {...activity, ...{name: activity.date}}
                          })
                        }}
                        variant={"outlined"}
                        optionValue={"id"}
                        optionLabel={"name"}
                        label={
                          <Fragment>
                            <Trans>Select activity</Trans> *
                          </Fragment>
                        }
                      />
                    </FormProvider>
                  </form>
                </Box>
              : <></>
              }
              {
                isValid && selectedActivity && <Box mt={4}>
                  {!uploaded && <Box>
                    <Trans>Upload report for activity</Trans>&nbsp;
                    {
                      selectedActivity.date
                    }
                    </Box>
                  }
                  <ReportUpload
                    on_upload={() => {
                      setUploaded(true)
                    }}
                    token={searchParams.get('token') ?? undefined}
                    cant_delete={true}
                    model_id={selectedActivityId}
                    model_type="activities"
                  />
                </Box>
              }
            </Box>
            { uploaded && <Alert icon={false} color={"success"}>
              <Trans>Report successfully uploaded</Trans>
            </Alert>
            }
          </Box>
        )}
        {failed && (
          <Box>
            <Box>
              <ErrorOutlineIcon sx={{ fontSize: "200px" }} color="error" />
            </Box>
            <Alert icon={false} color={"error"}>
              {failed}
            </Alert>
          </Box>
        )}
      </StyledMainWrapper>
    </Container>
  );
};

export default UploadPage;
